<template>
  <section
    :class="{
      'mb-2 mr-3 bg-white rounded-md shadow flex flex-col h-full': true,
      'h-full': !!detail,
    }"
  >
    <div class="flex w-full pl-3 md-max:pl-0 md-max:block">
      <div
        class="block w-3/5 px-6 pt-10 pb-15 md-max:px-8 md-max:w-full md-max:h-48 fake-grid-item-border-bottom"
      >
        <div class="flex pt-4 md-max:pt-6">
          <div class="mr-5">
            <img
              src="@/assets/icon/rental-duration.svg"
              class="w-12 h-12"
              alt="Rental Duration"
            />
          </div>
          <div class="flex flex-col">
            <div class="mb-2 text-sm font-bold">Rental Duration</div>
            <div class="text-sm text-color-sub">{{ startTime }}</div>
            <div class="text-sm text-color-sub">{{ endTime }}</div>
          </div>
        </div>

        <div class="flex pt-4">
          <div class="mr-5">
            <img
              src="@/assets/icon/delivery-address.svg"
              class="w-12 h-12"
              alt="Rental Duration"
            />
          </div>
          <div class="flex flex-col">
            <div class="mb-2 text-sm font-bold">
              Delivery &amp; Return Address
            </div>
            <div class="text-sm text-color-sub">{{ address }}</div>
          </div>
        </div>
      </div>

      <div class="grid w-2/5 grid-cols-2 md-max:w-full md-max:h-48">
        <div
          class="items-center grid-item place-content-center"
          v-for="(item, index) in categories"
          :key="index"
        >
          <trip-muti-icon :item="item" />
        </div>
      </div>
    </div>

    <div class="w-full flex-grow" v-if="!detail">
      &nbsp;
    </div>

    <div class="w-full flex-grow" v-if="detail">
      <gmap-map
        ref="map"
        :zoom="zoom"
        :center="center"
        map-type-id="terrain"
        style="width: 100%; height: 100%;"
        @click="onTabMap"
      >
        <gmap-marker
          :position="getLatLng(detail.src_coord)"
          :icon="{
            url: require(`@/assets/img/start_place.png`),
            scaledSize: { width: 63, height: 73 },
          }"
        >
        </gmap-marker>
        <gmap-marker
          v-if="!detail.drop_off_location && lastTripStatus === 'O'"
          :position="getLatLng(detail.bike.current_loc_coord)"
          :icon="{
            url: require(`@/assets/icon/status/vehicle-active.svg`),
            scaledSize: { width: 40, height: 40 },
          }"
        >
        </gmap-marker>
        <div v-if="getTripPath().length">
          <gmap-marker
            v-for="item in getTripPath()"
            :key="item.created_at"
            :position="getLatLng(item.location)"
            :icon="{
              url: require(`@/assets/img/icons/map_marker@3x.png`),
              scaledSize: { width: 30, height: 30 },
            }"
          >
          </gmap-marker>
        </div>
        <gmap-marker
          v-if="this.detail.dest_coord"
          :position="this.getLatLng(this.detail.dest_coord)"
          :icon="{
            url: require(`@/assets/img/end_place.png`),
            scaledSize: { width: 63, height: 73 },
          }"
        >
        </gmap-marker>
      </gmap-map>
    </div>
  </section>
</template>

<script>
import TripMutiIcon from './TripMultiIcon.vue'
import { gmapApi } from 'vue2-google-maps'

import ReconnectingWebsocket from 'reconnectingwebsocket'
import { SocketConfig } from '@/config/SocketConfig'

import { getFormattedDateTime } from '@/utils/datetime'

export default {
  components: {
    TripMutiIcon,
  },
  props: ['detail', 'rentalDetail', 'onTripStatusText', 'onTripStatusVariant'],
  computed: {
    google: gmapApi,
    lastTripStatus() {
      return this.$store.getters['lastTrip/getStatus']
    },
    startTime() {
      return getFormattedDateTime(this.rentalDetail.start_time)
    },
    endTime() {
      return getFormattedDateTime(this.rentalDetail.end_time)
    },
    address() {
      return this.rentalDetail
        ? this.rentalDetail.delivery_address || '--'
        : '--'
    },
  },
  beforeDestroy() {
    this.removeListener()
  },
  mounted() {
    this.categories.forEach((category) => {
      if (!this.detail) return '--'

      console.log('categ = ', category)
      if (category.id == 'bike') {
        category.title =
          this.detail && this.detail.bike
            ? this.detail.bike.bike_category == 'S'
              ? 'Bike'
              : this.detail.bike.bike_category == 'P'
              ? 'Scooter'
              : this.detail.bike.bike_category == 'E'
              ? 'EBike'
              : this.detail.bike.bike_category == 'C'
              ? 'Coco'
              : this.detail.bike.bike_category == 'K'
              ? 'Kenota'
              : this.detail.bike.bike_category == 'PP'
              ? 'Scooter Pro'
              : '--'
            : '--'
      } else if (category.id == 'reserve') {
        category.title = `${parseInt(
          this.detail.start_power_level
        )}% -> ${parseInt(this.detail.end_power_level)}%`
      } else if (category.id == 'co2') {
        const gallon = parseFloat(this.detail.trip.co2_saved).toFixed(1)
        category.title = gallon > 0 ? gallon + ' gallon' : '--'
      } else if (category.id == 'travel') {
        const distance = parseFloat(this.detail.trip.distance).toFixed(1)
        var toSet = '--'
        if (distance > 0) {
          toSet = parseFloat(distance / 1000).toFixed(2) + ' KM'
        }
        category.title = toSet
      }
    })

    console.log('detail = ', this.detail)

    if (this.detail) {
      let pos = this.getLatLng(this.detail.src_coord)
      this.center = {
        lat: pos.lat,
        lng: pos.lng,
      }
    }

    this.zoom = 17
    // setTimeout(() => {
    //   this.getDirection()
    // }, 1000)
    setTimeout(() => {
      this.boundingExtenders = this.getMapBoundExtenders()
    }, 1500)

    if (
      this.detail &&
      !this.detail.drop_off_location &&
      this.lastTripStatus === 'O'
    ) {
      this.startListener()
    }
  },
  data() {
    return {
      travelMode: 'DRIVING',
      boundingExtenders: [],
      sockListener: null,
      onTripStatusData: {
        text: 'On Trip',
        variant: 'purple',
      },
      categories: [
        {
          id: 'bike',
          icon_class: 'fas fa-bicycle category-icon',
          title: 'BIKE',
          subTitle: 'Vehicle',
          color: '#37b4ff',
        },
        {
          id: 'travel',
          icon_class: 'fas fa-road category-icon',
          title: '--',
          subTitle: 'Travelled',
          color: '#00b52c',
        },
        {
          id: 'co2',
          title: '--',
        },
        {
          id: 'reserve',
          icon_class: 'fas fa-battery-quarter category-icon',
          title: '1%',
          subTitle: 'Battery',
          color: '#9b37ff',
        },
      ],
      center: {
        lat: 10,
        lng: 10,
      },
      origin: null,
      destination: null,
      canceled: false,
      zoom: 12,
      showInfo: false,
      infoWIndowContext: {
        position: {
          lat: 44.2899,
          lng: 11.8774,
        },
      },
      vehicles: [
        {
          id: 'S',
          name: 'Scooter',
        },
        {
          id: 'P',
          name: 'Bike',
        },
        {
          id: 'E',
          name: 'EBike',
        },
      ],
    }
  },
  watch: {
    boundingExtenders(val) {
      this.resetMapBounds(val)
    },
    onTripStatusData: {
      deep: true,
      immediate: true,
      handler(val) {
        console.log('onTripStatusData updated', val)
      },
    },
  },
  methods: {
    getTripPath() {
      if (!this.detail) return []

      if (this.detail.traversed_path.length) {
        return this.detail.traversed_path
      } else if (this.detail.on_ride_trip_path.length) {
        return this.detail.on_ride_trip_path
      } else {
        return []
      }
    },
    getMapBoundExtenders() {
      if (!this.detail) return []

      var extenders = [this.getLatLng(this.detail.src_coord)]
      if (!this.detail.dest_coord && this.lastTripStatus === 'O') {
        extenders.push(this.getLatLng(this.detail.bike.current_loc_coord))
      }
      if (this.detail.dest_coord && this.lastTripStatus !== 'O') {
        extenders.push(this.getLatLng(this.detail.dest_coord))
      }
      return extenders
    },
    resetMapBounds(bounds) {
      if (!this.$refs.map) return
      var updatedBounds = new this.google.maps.LatLngBounds()
      for (const bound of bounds) {
        updatedBounds.extend(bound)
      }
      this.$refs.map.fitBounds(updatedBounds)
    },
    toggleInfoWindow(context) {
      this.infoWIndowContext = context
      this.showInfo = true
    },
    infoClicked(context) {
      console.log(context)
    },
    // getDirection() {
    //   var directionsService = new this.google.maps.DirectionsService()
    //   var directionsDisplay = new this.google.maps.DirectionsRenderer({
    //     suppressMarkers: true,
    //   })

    //   console.log('dire = ', this.$refs.map.$mapObject)
    //   directionsDisplay.setMap(this.$refs.map.$mapObject)

    //   var vm = this

    //   directionsService.route(
    //     {
    //       origin: this.getLatLng(this.detail.src_coord),
    //       destination: this.getLatLng(this.detail.dest_coord),
    //       travelMode: 'DRIVING',
    //     },
    //     function(response, status) {
    //       if (status === 'OK') {
    //         directionsDisplay.setDirections(response)
    //         var leg = response.routes[0].legs[0]
    //         vm.makeMarker(leg.start_location, 'start', 'title')
    //         vm.makeMarker(leg.end_location, 'end', 'title')
    //       } else {
    //         console.log('response = ', response)
    //         vm.makeMarker(response.request.origin.location, 'start', 'title')
    //         //window.alert('Directions request failed due to ' + status);
    //       }
    //     }
    //   )
    // },
    // makeMarker(position, icon, title) {
    //   new this.google.maps.Marker({
    //     position: position,
    //     map: this.$refs.map.$mapObject,
    //     icon:
    //       icon == 'start'
    //         ? {
    //             url: require(`@/assets/img/start_place.png`),
    //             scaledSize: { width: 63, height: 73 },
    //           }
    //         : {
    //             url: require(`@/assets/img/end_place.png`),
    //             scaledSize: { width: 63, height: 73 },
    //           },
    //     title: title,
    //   })
    // },
    onTabMap(event) {
      console.log(event.latLng.lat(), event.latLng.lng())
    },
    getLatLng(position) {
      if (position == '' || position == null)
        return {
          lat: 0,
          lng: 0,
        }
      let lat = parseFloat(position.split(',')[0])
      let lng = parseFloat(position.split(',')[1])
      return {
        lat: lat,
        lng: lng,
      }
    },
    startListener() {
      let token = localStorage.getItem('token') || null
      // console.log(`Fetched Token : ${token}`)
      if (!token) return
      let sockUrl =
        SocketConfig.baseUrl +
        SocketConfig.channels.bikeUpdates +
        `?token=${token}`

      this.sockListener = this.sockListener
        ? this.sockListener
        : new ReconnectingWebsocket(sockUrl, null, SocketConfig.configs())

      this.sockListener.onopen = function() {
        console.log('Opened Connection...')
      }
      this.sockListener.onclose = function() {
        console.log('Closed Connection...')
      }
      this.sockListener.onmessage = this.onMessageHandler
    },
    removeListener() {
      if (this.sockListener) {
        this.sockListener.close(
          4003,
          'oto system closed the socket to refresh stream'
        )
        this.sockListener = null
      }
    },
    onMessageHandler(message) {
      console.log('Incoming --> ', message)
      let payload = JSON.parse(message.data)
      if (payload.n_type !== 'noti.bike_updates') return

      var data = payload.data
      // console.log('Recieved Payload...', data)

      let bikeId = data['id']
      if (!bikeId) return
      if (this.detail.bike.id !== bikeId) return

      console.log(
        `Incoming data for bike ${bikeId} : location --> ${data['location']}`
      )

      if (data.status_flags) {
        console.log(
          `Incoming data for bike ${bikeId} : on ride status --> ${data.status_flags.is_on_ride}`
        )
        if (!data.status_flags.is_on_ride) {
          this.removeListener()
          this.$emit('fetchTripDetail', this.detail.id)
          return
        }
      }
      console.log(
        `Incoming data for bike ${bikeId} : on ride status --> ${data.status_flags.is_on_ride}`
      )
      if (this.detail.bike.current_loc_coord === data['location']) {
        console.log(
          `Skipping updates : Has same data --> ${this.detail.bike.current_loc_coord}`
        )
      } else {
        const prevData = this.detail.bike.current_loc_coord
        this.detail.bike.current_loc_coord = data['location']
        console.log(
          `Updated data for bike ${bikeId} | location : ${prevData} --> ${data['location']}`
        )
      }
      this.boundingExtenders = this.getMapBoundExtenders()
    },
  },
}
</script>

<style>
.arrow {
  height: 80px;
  width: 1px;
  /* background: #dfdfdf; */
  margin-left: 14px;
  border-right: 2px dotted #dfdfdf;
}
.arrow i {
  color: #dfdfdf;
  position: absolute;
  left: -4px;
  bottom: -10px;
}

.fake-grid-item-border-bottom {
  border-bottom: 2px solid #f6f6f6 !important;
}

@media (max-width: 990px) {
  .arrow {
    height: 60px;
  }
}
</style>
