var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:{
    'mb-2 mr-3 bg-white rounded-md shadow flex flex-col h-full': true,
    'h-full': !!_vm.detail,
  }},[_c('div',{staticClass:"flex w-full pl-3 md-max:pl-0 md-max:block"},[_c('div',{staticClass:"block w-3/5 px-6 pt-10 pb-15 md-max:px-8 md-max:w-full md-max:h-48 fake-grid-item-border-bottom"},[_c('div',{staticClass:"flex pt-4 md-max:pt-6"},[_vm._m(0),_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"mb-2 text-sm font-bold"},[_vm._v("Rental Duration")]),_c('div',{staticClass:"text-sm text-color-sub"},[_vm._v(_vm._s(_vm.startTime))]),_c('div',{staticClass:"text-sm text-color-sub"},[_vm._v(_vm._s(_vm.endTime))])])]),_c('div',{staticClass:"flex pt-4"},[_vm._m(1),_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"mb-2 text-sm font-bold"},[_vm._v(" Delivery & Return Address ")]),_c('div',{staticClass:"text-sm text-color-sub"},[_vm._v(_vm._s(_vm.address))])])])]),_c('div',{staticClass:"grid w-2/5 grid-cols-2 md-max:w-full md-max:h-48"},_vm._l((_vm.categories),function(item,index){return _c('div',{key:index,staticClass:"items-center grid-item place-content-center"},[_c('trip-muti-icon',{attrs:{"item":item}})],1)}),0)]),(!_vm.detail)?_c('div',{staticClass:"w-full flex-grow"},[_vm._v("   ")]):_vm._e(),(_vm.detail)?_c('div',{staticClass:"w-full flex-grow"},[_c('gmap-map',{ref:"map",staticStyle:{"width":"100%","height":"100%"},attrs:{"zoom":_vm.zoom,"center":_vm.center,"map-type-id":"terrain"},on:{"click":_vm.onTabMap}},[_c('gmap-marker',{attrs:{"position":_vm.getLatLng(_vm.detail.src_coord),"icon":{
          url: require("@/assets/img/start_place.png"),
          scaledSize: { width: 63, height: 73 },
        }}}),(!_vm.detail.drop_off_location && _vm.lastTripStatus === 'O')?_c('gmap-marker',{attrs:{"position":_vm.getLatLng(_vm.detail.bike.current_loc_coord),"icon":{
          url: require("@/assets/icon/status/vehicle-active.svg"),
          scaledSize: { width: 40, height: 40 },
        }}}):_vm._e(),(_vm.getTripPath().length)?_c('div',_vm._l((_vm.getTripPath()),function(item){return _c('gmap-marker',{key:item.created_at,attrs:{"position":_vm.getLatLng(item.location),"icon":{
            url: require("@/assets/img/icons/map_marker@3x.png"),
            scaledSize: { width: 30, height: 30 },
          }}})}),1):_vm._e(),(this.detail.dest_coord)?_c('gmap-marker',{attrs:{"position":this.getLatLng(this.detail.dest_coord),"icon":{
          url: require("@/assets/img/end_place.png"),
          scaledSize: { width: 63, height: 73 },
        }}}):_vm._e()],1)],1):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mr-5"},[_c('img',{staticClass:"w-12 h-12",attrs:{"src":require("@/assets/icon/rental-duration.svg"),"alt":"Rental Duration"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mr-5"},[_c('img',{staticClass:"w-12 h-12",attrs:{"src":require("@/assets/icon/delivery-address.svg"),"alt":"Rental Duration"}})])}]

export { render, staticRenderFns }