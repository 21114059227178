<template>
  <div class="mb-2 mt-3">
    <section class="timeline-section">
      <div>
        <span class="font-medium text-left" v-text="`Rental Timeline`" />
      </div>

      <div class="flex items-center justify-center">
        <div class="tab-items">
          <div
            v-for="tab in tabs"
            :key="`tab-item-${tab.id}`"
            :class="getTabClass(tab.id)"
            v-text="tab.text"
            @click="onClickTab(tab.id)"
          />
        </div>
      </div>

      <div class="flex justify-center md:justify-end">
        <AppButton :is-loading="isLoading" variant="transparent" text="" />
      </div>
    </section>

    <TimelineGroup
      :isLoading="isLoading"
      :logs="logs"
      :timeline-type="timelineType"
    />
  </div>
</template>

<script>
import { useEndpoints } from '@/composables'
import TimelineGroup from '@/components/timeline-new/TimelineGroup.vue'
// import OtoIcon from '@/components/ui/OtoIcon.vue'

export default {
  name: 'TimelineHOC',

  props: {
    rentalId: {
      type: String,
      required: true,
    },
  },

  components: {
    TimelineGroup,
    // NearbyApi,
    // OtoIcon,
  },

  data() {
    return {
      isLoading: false,
      logs: [],
      tabs: [
        { id: 'user', text: 'Rental' },
        { id: 'vehicle', text: 'Vehicle' },
      ],
      activeTabId: 'user',
    }
  },

  async created() {
    await this.onClickTab('user')
  },

  computed: {
    timelineType() {
      if (this.activeTabId === 'user') {
        return 'rental'
      }

      if (this.activeTabId === 'vehicle') {
        return 'vehicle'
      }

      return 'rental'
    },
  },

  methods: {
    async refreshLogs() {
      await this.fetchLogs(this.activeTabId)
    },

    async fetchLogs(type = 'vehicle') {
      this.isLoading = true
      const url =
        type === 'vehicle'
          ? useEndpoints.vehicleRental.vehicleTimelineLog(this.rentalId)
          : useEndpoints.vehicleRental.userTimelineLog(this.rentalId)

      await this.$http
        .get(url)
        .then((res) => {
          console.log('fetchLogs res = ', res.data['data'])
          this.logs = res.data['data']
        })
        .catch((err) => {
          console.error('fetchLogs err = ', err, err.response.data)
        })
        .finally(() => (this.isLoading = false))
    },

    getTabClass(tabId) {
      return {
        'tab-item': true,
        'tab-item--active': this.activeTabId === tabId,
      }
    },

    async onClickTab(tabId) {
      this.activeTabId = tabId
      await this.fetchLogs(this.activeTabId)
    },
  },
}
</script>

<style lang="scss" scoped>
.timeline-section {
  min-height: 4rem;
  @apply grid items-center w-full px-6 py-1 bg-white rounded-md shadow;
  @apply grid-cols-1 md:grid-cols-3;
}

.tab-items {
  @apply flex max-w-sm rounded-full bg-gray-200 py-1 px-2 gap-2 transition-all duration-200 ease-in-out;

  .tab-item {
    min-width: 80px;
    @apply flex items-center justify-center text-center text-xs cursor-pointer py-2 px-2 font-medium text-gray-700 rounded-full hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900;

    &--active {
      @apply bg-black text-white;
      &:hover {
        @apply bg-black text-white;
      }
    }
  }
}
</style>
