<template>
  <div class="p-5 rental-status-detail">
    <div class="flex items-center">
      <div class="mr-4 ripple-container flex items-center justify-center">
        <div class="circle-ripple"></div>
      </div>
      <div class="flex-grow">
        <div class="text-base fg-fig-green">{{ message }}</div>
      </div>
      <div class="">
        <div class="text-sm text-color-sub">
          <i class="fas fa-circle-notch fg-fig-green text-xl"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ripple-block',
  components: {},
  props: {
    message: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.bg-fig-green {
  background-color: #27b06e !important;
}

.fg-fig-green {
  color: #27b06e !important;
}

.rental-status-detail-group .rental-status-detail:not(:first-child) {
  border-top: 1px solid #f2f2f2;
}

.circle-ripple {
  background-color: #27b06e;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  animation: ripple 0.7s linear infinite;
}

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 rgba(101, 255, 120, 0.3),
      0 0 0 0px rgba(101, 255, 120, 0.3), 0 0 0 8px rgba(101, 255, 120, 0.3),
      0 0 0 16px rgba(101, 255, 120, 0.3);
  }
  100% {
    box-shadow: 0 0 0 1em rgba(101, 255, 120, 0.3),
      0 0 0 2px rgba(101, 255, 120, 0.3), 0 0 0 10px rgba(101, 255, 120, 0.3),
      0 0 0 18px rgba(101, 255, 120, 0);
  }
}

.ripple-container {
  width: 30px;
  height: 30px;
  // overflow: hidden;
}
</style>
