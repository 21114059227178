<template>
  <section class="bg-white rounded-md shadow">
    <div class="relative">
      <div class=" flex items-center w-full h-full place-content-center py-5">
        <div class="">
          <span class="block cost-total-title"
            >{{ rentalDetail.invoice.currency.symbol }}
            {{ parseFloat(rentalDetail.invoice.amount).toFixed(2) }}</span
          >
          <span class="block cost-sub-title">Rent Cost</span>
        </div>
      </div>
      <template v-if="isRefunded">
        <div class="absolute" style="right: 5%; top: 5%">
          <img src="@/assets/img/payment/refunded-seal.png" class="w-32 h-32" />
        </div>
      </template>
      <!-- <img
        src="@/assets/img/bg/group-43.png"
        class="w-full cost-bg"
        resizeMode="contain"
      /> -->
    </div>

    <div class="px-6">
      <div
        class="cost-list"
        v-for="(category, index) in costCategories"
        :key="index"
      >
        <div class="col-w-8">
          <span
            class="cost-title"
            :style="
              category.name == 'Discount' ? 'color:#3ebc30' : 'color:#000000'
            "
            >{{ getCostTitleLine(category.name) }}</span
          >
        </div>
        <div class="justify-end text-right col-w-2">
          <span
            class="cost-val"
            :style="
              category.name == 'Discount' ? 'color:#3ebc30' : 'color:#000000'
            "
            >{{ category.price }}</span
          >
        </div>
      </div>

      <div class="cost-list no-border">
        <div class="col-w-7">
          <i class="fas fa-money-check"></i>
          <span class="ml-3 cost-title">Payment</span>
        </div>
        <div class="col-w-3" style="text-align: end">
          <span class="cost-val" v-if="getPaymentMethod">
            {{ getPaymentMethod }}
          </span>
        </div>
      </div>
    </div>

    <div class="rental-status-detail-group mt-2">
      <div
        class="bg-fig-green text-white p-5 rental-status-detail"
        v-if="rentalDetail !== 'REQUESTED'"
      >
        <div class="flex items-center justify-center">
          <div class="mr-2"><i class="fas fa-stopwatch"></i></div>
          <div>Renting time left</div>
        </div>
        <div class="flex items-center justify-center mt-2">
          <div class="text-3xl font-bold">{{ rentTimeLeft }}</div>
        </div>
      </div>

      <div class="p-5 rental-status-detail">
        <div class="flex items-center">
          <div class="mr-4">
            <i class="fas fa-check-square fg-fig-green text-3xl"></i>
          </div>
          <div class="flex-grow">
            <div class="text-base">Rent Requested</div>
            <div class="text-sm text-color-sub">
              Rent ID:
              <span class="">
                #{{ rentalDetail.id.substr(rentalDetail.id.length - 5) }}
              </span>
            </div>
          </div>
          <div class="">
            <div class="text-sm text-color-sub">
              {{ getFormattedDateTime(rentalDetail.requested_at) }}
            </div>
          </div>
        </div>
      </div>

      <ripple-block
        v-if="rentalDetail.status === 'REQUESTED'"
        :message="`Waiting for Approval`"
      />

      <div class="p-5 rental-status-detail" v-if="rentalDetail.accepted_at">
        <div class="flex items-center">
          <div class="mr-4">
            <i class="fas fa-check-square fg-fig-green text-3xl"></i>
          </div>
          <div class="flex-grow">
            <div class="text-base">Request Accepted</div>
          </div>
          <div class="">
            <div class="text-sm text-color-sub">
              {{ getFormattedDateTime(rentalDetail.accepted_at) }}
            </div>
          </div>
        </div>
      </div>

      <ripple-block
        v-if="rentalDetail.status === 'ACCEPTED'"
        :message="`Waiting for Delivery`"
      />

      <div class="p-5 rental-status-detail" v-if="rentalDetail.delivered_at">
        <div class="flex items-center">
          <div class="mr-4">
            <i class="fas fa-check-square fg-fig-green text-3xl"></i>
          </div>
          <div class="flex-grow">
            <div class="text-base">Vehicle Delivered</div>
            <div class="text-sm text-color-sub">
              Vehicle QR:
              <router-link
                :to="{
                  name: 'ViewVehicleProfile',
                  params: { id: rentalDetail.vehicle.id },
                }"
              >
                <span class="text-blue-500 text-sm">
                  {{ rentalDetail.vehicle.qr_code }}
                </span>
              </router-link>
            </div>
          </div>
          <div class="">
            <div class="text-sm text-color-sub">
              {{ getFormattedDateTime(rentalDetail.delivered_at) }}
            </div>
          </div>
        </div>
      </div>

      <div class="p-5 rental-status-detail" v-if="detail">
        <div class="flex items-center">
          <div class="mr-4">
            <i class="fas fa-check-square fg-fig-green text-3xl"></i>
          </div>
          <div class="flex-grow">
            <div class="text-base">Rent Started</div>
            <div class="text-sm text-color-sub">
              Trip ID:
              <router-link
                :to="{
                  name: 'ViewTrip',
                  params: { id: detail.id },
                }"
              >
                <span class="text-blue-500 text-sm">
                  #{{ detail.id.substr(detail.id.length - 5) }}
                </span>
              </router-link>
            </div>
          </div>
          <div class="">
            <div class="text-sm text-color-sub">
              {{ getFormattedDateTime(detail.pick_up_time) }}
            </div>
          </div>
        </div>
      </div>

      <ripple-block
        v-if="rentalDetail.status === 'DELIVERED'"
        :message="`Waiting for Completion`"
      />

      <div
        class="p-5 rental-status-detail"
        v-if="rentalDetail.status === 'TRIP_COMPLETED'"
      >
        <div class="flex items-center">
          <div class="mr-4">
            <i class="fas fa-check-square fg-fig-green text-3xl"></i>
          </div>
          <div class="flex-grow">
            <div class="text-base">Rent Completed</div>
            <div class="text-sm text-color-sub">
              {{ rentalDetail.delivery_address }}
            </div>
          </div>
        </div>
      </div>

      <div
        class="p-5 rental-status-detail"
        v-if="rentalDetail.status === 'REJECTED'"
      >
        <div class="flex items-center">
          <div class="mr-4">
            <i class="fas fa-times-circle text-3xl text-oRed"></i>
          </div>
          <div class="flex-grow">
            <div class="text-base text-oRed">Request Rejected</div>
            <div class="text-sm text-color-sub">
              Reason:
              <span class="text-sm">
                {{ rentalDetail.reject_reason || 'N/A' }}
              </span>
            </div>
          </div>
          <div class="">
            <div class="text-sm text-color-sub">
              {{ getFormattedDateTime(rentalDetail.rejected_at) }}
            </div>
          </div>
        </div>
      </div>

      <div
        class="p-5 rental-status-detail"
        v-if="rentalDetail.cancel_requested_at"
      >
        <div class="flex items-center">
          <div class="mr-4">
            <i class="fas fa-exclamation-triangle text-2xl"></i>
          </div>
          <div class="flex-grow">
            <div class="text-base">Cancellation Request by Rider</div>
            <div class="text-sm text-color-sub">
              Reason:
              <span class="text-sm">
                {{ rentalDetail.cancel_request_reason || 'N/A' }}
              </span>
            </div>
          </div>
          <div class="">
            <div class="text-sm text-color-sub">
              {{ getFormattedDateTime(rentalDetail.cancel_requested_at) }}
            </div>
          </div>
        </div>
      </div>

      <div
        class="p-5 rental-status-detail"
        v-if="rentalDetail.status === 'CANCELLED'"
      >
        <div class="flex items-center">
          <div class="mr-4">
            <i class="fas fa-exclamation-triangle text-2xl text-oRed"></i>
          </div>
          <div class="flex-grow">
            <div class="text-base text-oRed">Rent Cancelled</div>
            <div class="text-sm text-color-sub">
              Reason:
              <span class="text-sm">
                {{ rentalDetail.cancel_reason || 'N/A' }}
              </span>
            </div>
          </div>
          <div class="">
            <div class="text-sm text-color-sub">
              {{ getFormattedDateTime(rentalDetail.cancelled_at) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import moment from 'moment'

import { getFormattedDateTime as getFormattedDateTimeBase } from '@/utils/datetime'
import RippleBlock from './RippleBlock.vue'
import { milisecToDHMSConverter } from '@/components/picker/date-range/utils/DatesUtils'

export default {
  components: {
    RippleBlock,
  },
  props: {
    detail: {
      required: false,
      default: null,
    },
    rentalDetail: {
      required: false,
      default: null,
    },
    symbol: {
      required: false,
    },
  },
  mounted() {
    this.costCategories.forEach((cost) => {
      if (cost.id == 'discount') {
        cost.price = this.getFormattedPrice(this.rentalDetail.discount)
      } else if (cost.id == 'cost') {
        cost.price = this.getFormattedPrice(this.rentalDetail.settled_amount)
      } else if (cost.id == 'total') {
        cost.price = this.getFormattedPrice(this.rentalDetail.invoice.amount)
      } else if (cost.id == 'tax_amount') {
        cost.price = this.getFormattedPrice(
          this.rentalDetail.settled_tax_amount
        )
      }
    })

    const status = this.rentalDetail.status

    if (status === 'CANCELLED' || status === 'REJECTED') {
      this.rentTimeLeft = status
    } else if (status !== 'DELIVERED' && status !== 'TRIP_STARTED') {
      this.rentTimeLeft = '--'
    } else {
      this.updateTimeLeft()

      const self = this

      this.timeLeftInterval = setInterval(() => {
        self.updateTimeLeft()
      }, 1000 * 1)
    }
  },
  beforeDestroy() {
    if (this.timeLeftInterval) {
      clearInterval(this.timeLeftInterval)
    }
  },
  computed: {
    isRefunded() {
      return this.rentalDetail.invoice && this.rentalDetail.invoice.is_refunded
    },
    getPaymentMethod() {
      if (!this.rentalDetail.invoice) return null
      const paymentMethod = this.rentalDetail.invoice.payment_method
      return paymentMethod
    },
  },
  data() {
    return {
      onTripDurationStr: null,
      rentTimeLeft: '--',
      costCategories: [
        {
          id: 'cost',
          name: 'Cost',
          price: '0',
        },
        {
          id: 'discount',
          name: 'Discount',
          price: '0',
        },
        {
          id: 'tax_amount',
          name: 'VAT',
          price: '0',
        },
        {
          id: 'total',
          name: 'Sub Total',
          price: '0',
        },
      ],
    }
  },
  methods: {
    getFormattedDateTime(...args) {
      return getFormattedDateTimeBase(...args)
    },
    updateTimeLeft() {
      const mobj = moment.duration(
        moment(this.rentalDetail.delivered_at)
          .add(this.rentalDetail.duration, 'h')
          .diff(moment())
      )

      const d = mobj.days()
      const h = mobj.hours()
      const m = mobj.minutes()
      const s = mobj.seconds()

      if (d) {
        this.rentTimeLeft = `${d} d ${h} h ${m} m`
      } else if (h) {
        this.rentTimeLeft = `${h} h ${m} m ${s} s`
      } else if (m) {
        this.rentTimeLeft = `${m} m ${s} s`
      } else if (s) {
        this.rentTimeLeft = `${s} s`
      } else {
        this.rentTimeLeft = '--'
      }

      // this.rentTimeLeft = m.fromNow(true)
    },
    getFormattedPrice(price) {
      return (
        this.rentalDetail?.invoice?.currency?.symbol +
        ' ' +
        parseFloat(price).toFixed(2)
      )
    },
    getCostTitleLine(categoryName) {
      if (categoryName === 'Discount') {
        return 'Discount'
      }

      if (categoryName === 'Sub Total') {
        return 'Sub Total'
      }

      if (categoryName === 'VAT') {
        return `VAT ${this.rentalDetail.tax_percentage}%`
      }

      if (categoryName === 'Cost') {
        return `Rental Cost (${milisecToDHMSConverter(
          this.rentalDetail.duration_in_seconds * 1000
        )})`
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.bg-fig-green {
  background-color: #27b06e !important;
}

.fg-fig-green {
  color: #27b06e !important;
}

.rental-status-detail-group .rental-status-detail:not(:first-child) {
  border-top: 1px solid #f2f2f2;
}
</style>
