var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex gap-2 items-start mt-3"},[_c('div',{staticClass:"w-12 h-10 bg-gray-100 rounded-full"},[(
        _vm.data.created_by &&
          _vm.data.created_by.avatar_url &&
          _vm.data.created_by.avatar_url !== ''
      )?_c('img',{staticClass:"creator-avatar",attrs:{"src":_vm.data.created_by.avatar_url}}):_vm._e()]),_c('div',{staticClass:"w-full"},[_c('div',[_c('span',{staticClass:"text-sm font-bold"},[_vm._v(" "+_vm._s(_vm.data.created_by.full_name || '--')+" ")]),_c('span',{staticClass:"pl-2 text-xs text-gray-500"},[_vm._v(" "+_vm._s(_vm.data.created_by.role_name || '--')+" ")])]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.data.details)}}),_c('small',{staticClass:"text-gray-500"},[_vm._v(" "+_vm._s(_vm.getTimeAgo(_vm.data.created_at))+" ")])]),_c('div',{},[_c('TDropdown',{attrs:{"variant":"smActions"},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
        var mousedownHandler = ref.mousedownHandler;
        var focusHandler = ref.focusHandler;
        var blurHandler = ref.blurHandler;
        var keydownHandler = ref.keydownHandler;
return [_c('button',{staticClass:"app-form-reset",attrs:{"aria-label":"Report Status Menu","aria-haspopup":"true"},on:{"mousedown":mousedownHandler,"focus":focusHandler,"blur":blurHandler,"keydown":keydownHandler}},[_c('i',{staticClass:"text-sm text-gray-400 fas fa-ellipsis-v"})])]}},{key:"default",fn:function(){return [_c('div',{staticClass:"py-1 bg-white rounded shadow"},[_c('div',{staticClass:"\n              py-2\n              px-4\n              text-sm text-red-500\n              cursor-pointer\n              hover:bg-red-100\n            ",on:{"click":function($event){return _vm.onDelete(_vm.data)}}},[_vm._v(" Delete ")])])]},proxy:true}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }