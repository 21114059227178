<template>
  <div>
    <AcceptModal
      v-if="showAcceptModal"
      :rent-id="currentRentId"
      v-on:close="onAcceptModalClose"
    />
    <RejectModal
      v-if="showRejectModal"
      :rent-id="currentRentId"
      v-on:close="onRejectModalClose"
    />
    <CancelModal
      v-if="showCancelModal"
      :rent-id="currentRentId"
      v-on:close="onCancelModalClose"
    />
    <DeliverModal
      v-if="showDeliverModal"
      :rent-id="currentRentId"
      v-on:close="onDeliverModalClose"
    />

    <section
      class="
        w-full
        px-4
        py-3
        mb-2
        bg-white
        rounded-md
        shadow
        responsive-header-section
      "
    >
      <div class="flex flex-wrap items-center w-full h-full">
        <div class="items-center left-trip">
          <div
            class="items-center place-content-center back-arrow"
            @click="goBack"
          >
            <i class="fas fa-arrow-left"></i>
          </div>
          <span>Rent Details (ID: #{{ rentalDetail.id | onlyLastFive }})</span>
          <span id="myDiv" ref="myDiv"></span>
        </div>

        <div class="block lg:flex right-trip lg:items-center">
          <div class="ml-2">
            <button
              @click="$emit('download-invoice')"
              class="
                flex
                items-center
                justify-between
                px-4
                py-0
                font-medium
                border
                rounded-full
                shadow-sm
                text-14px text-oCharcoal
                focus:border-oCharcoal
                hover:border-oCharcoal
                focus:outline-none
              "
              style="height: 35px"
            >
              <div class="flex items-center">
                <svg
                  class="w-6 h-4"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                  ></path>
                </svg>
                <span class="text-gray-700"> Download Invoice </span>
              </div>
            </button>
          </div>
          <!-- <div class="flex items-center ml-2">
            <ExportTripBlock />
          </div> -->

          <div class="flex items-center mt-2 ml-2 lg:mt-0">
            <template
              v-if="
                rentalDetail.status === 'REQUESTED' ||
                  rentalDetail.status === 'ACCEPTED' ||
                  rentalDetail.status === 'DELIVERED' ||
                  rentalDetail.status === 'TRIP_STARTED'
              "
            >
              <div>
                <t-dropdown variant="smActions">
                  <template
                    #trigger="{
                        mousedownHandler,
                        focusHandler,
                        blurHandler,
                        keydownHandler,
                    }"
                  >
                    <button
                      aria-label="Rent Status Dropdown"
                      :class="
                        `rental-status-pill-button ${getStatusVariant} focus:outline-none`
                      "
                      style="height: 35px; width: 170px"
                      aria-haspopup="true"
                      @mousedown="mousedownHandler"
                      @focus="focusHandler"
                      @blur="blurHandler"
                      @keydown="keydownHandler"
                    >
                      <span>{{ getStatusText }}</span>
                      <i class="fas fa-chevron-down" />
                    </button>
                  </template>

                  <template #default="{ hide }">
                    <div
                      @click="hide()"
                      class="py-1 mt-1 bg-white rounded-md shadow-md"
                    >
                      <t-dropdown-item
                        @click="
                          onPerformAction({
                            id: rentalDetail.id,
                            action: 'accept',
                          })
                        "
                        v-if="rentalDetail.status === 'REQUESTED'"
                        class="block px-3 py-1.5 cursor-pointer text-oOrange hover:bg-orange-100"
                      >
                        Accept
                      </t-dropdown-item>
                      <t-dropdown-item
                        @click="
                          onPerformAction({
                            id: rentalDetail.id,
                            action: 'reject',
                          })
                        "
                        v-if="rentalDetail.status === 'REQUESTED'"
                        class="block px-3 py-1.5 cursor-pointer text-oOrange hover:bg-orange-100"
                      >
                        Reject
                      </t-dropdown-item>
                      <t-dropdown-item
                        @click="
                          onPerformAction({
                            id: rentalDetail.id,
                            action: 'deliver',
                          })
                        "
                        v-if="rentalDetail.status === 'ACCEPTED'"
                        class="block px-3 py-1.5 cursor-pointer text-oOrange hover:bg-orange-100"
                      >
                        Mark as Delivered
                      </t-dropdown-item>
                      <t-dropdown-item
                        @click="
                          onPerformAction({
                            id: rentalDetail.id,
                            action: 'cancel',
                          })
                        "
                        v-if="
                          rentalDetail.status !== 'REQUESTED' &&
                            rentalDetail.status !== 'ACCEPTED' &&
                            rentalDetail.status !== 'TRIP_COMPLETED' &&
                            rentalDetail.status !== 'CANCELLED' &&
                            rentalDetail.status !== 'REJECTED'
                        "
                        class="block px-3 py-1.5 cursor-pointer text-oOrange hover:bg-orange-100"
                      >
                        Cancel
                      </t-dropdown-item>
                    </div>
                  </template>
                </t-dropdown>
              </div>
            </template>
            <template v-else>
              <StatusPillButton :status="rentalDetail.status" />
            </template>
          </div>
        </div>
      </div>
    </section>

    <section
      v-if="rentalDetail.rider"
      class="
        flex flex-col
        items-start
        justify-start
        w-full
        px-4
        py-3
        mb-2
        space-y-5
        bg-white
        rounded-md
        shadow
        md:space-y-0 md:items-center md:flex-row md:justify-between
        responsive-header-section
      "
    >
      <div class="flex items-center w-full space-x-3 md:w-1/3">
        <DisplayAvatar
          :avatar="rentalDetail.rider.profile_pic"
          :width="`68px`"
          :height="`68px`"
        />
        <div>
          <h2 class="font-bold text-oDark text-14px">
            {{ rentalDetail.rider.full_name || '--' }}
          </h2>

          <div class="flex items-center mt-1 text-gray-500 text-14px">
            <i class="mr-2 text-lg fas fa-phone fa-flip-horizontal" />
            <span>{{ rentalDetail.rider.phone_number || '--' }}</span>
          </div>
        </div>
      </div>

      <div
        class="
          grid
          w-full
          grid-cols-2
          gap-y-3
          md:w-2/3 md:grid-cols-3 md:gap-y-0
        "
      >
        <!-- <div class="">
          <h2 class="font-bold text-gray-500 text-14px">Trip ID</h2>

          <router-link
            v-if="rentalDetail.trip"
            :to="{
              name: 'ViewTrip',
              params: { id: rentalDetail.trip.id },
            }"
            target="_blank"
          >
            <h4 class="font-bold text-oLink text-14px">
              {{ $truncate(rentalDetail.trip.id, -5, '#') }}
            </h4>
          </router-link>
          <h4 v-else class="font-bold text-oDark text-14px">--</h4>
        </div> -->

        <div class="">
          <h2 class="font-bold text-gray-500 text-14px">Invoice ID</h2>
          <h4 class="font-bold text-oDark text-14px">
            {{ getInvoiceId }}
          </h4>
        </div>

        <div class="">
          <h2 class="font-bold text-gray-500 text-14px">Transaction Date</h2>
          <h4 class="font-bold text-oDark text-14px">
            {{ getInvoiceDate }}
          </h4>
        </div>

        <div class="">
          <h2 class="font-bold text-gray-500 text-14px">Vehicle QR</h2>

          <router-link
            v-if="rentalDetail.vehicle"
            :to="{
              name: 'ViewVehicleProfile',
              params: { id: rentalDetail.vehicle.id },
            }"
            target="_blank"
          >
            <h4 class="font-bold text-oLink text-14px">
              {{ rentalDetail.vehicle.qr_code || '--' }}
            </h4>
          </router-link>
          <h4 v-else class="text-blue-500 text-14px">--</h4>
        </div>
      </div>
    </section>

    <div class="grid w-full grid-cols-1 md:grid-cols-5">
      <div class="col-span-1 md:col-span-3">
        <trip-google-map-view
          :rental-detail="rentalDetail"
          :detail="tripDetail"
          :onTripStatusText="onTripStatusText"
          :onTripStatusVariant="onTripStatusVariant"
          ref="tripGmapView"
          id="tripGmapView"
        />
      </div>

      <div class="col-span-1 md:col-span-2">
        <trip-cost-view
          :detail="tripDetail"
          :rental-detail="rentalDetail"
          :symbol="symbol"
        />
      </div>
    </div>

    <div class="grid w-full grid-cols-1 md:grid-cols-5 mt-3">
      <div class="col-span-1 md:col-span-3">
        <!-- notes -->
        <TripNoteView :id="rentalDetail.id" />
        <!-- /notes -->
      </div>

      <!-- tags -->
      <div class="col-span-1 md:col-span-2">
        <TripTagView :id="rentalDetail.id" />
      </div>
      <!-- /tags -->
    </div>

    <RentalTimeline :rentalId="rentalDetail.id" ref="rentalTimeline" />
  </div>
</template>

<script>
import TripGoogleMapView from './blocks/TripGoogleMapView'
import TripCostView from './blocks/TripCostView'
// import TripPointView from './blocks/TripPointView'
import TripNoteView from './blocks/note/TripNoteView'
import TripTagView from './blocks/TripTagView'

import { mapGetters } from 'vuex'
// import ExportTripBlock from '@/composites/trip/details/blocks/ExportTripBlock.vue'

import RentalTimeline from '@/composites/vehicle-rental/details/RentalTimeline.vue'
import { SocketConfig } from '@/config/SocketConfig'
import { getUTCAwareTime } from '@/utils'

import ReconnectingWebsocket from 'reconnectingwebsocket'
import TDropdownItem from '@/components/dropdown/TDropdownItem'

export default {
  components: {
    TripGoogleMapView,
    TripCostView,
    TripNoteView,
    TripTagView,
    TDropdownItem,
    // ExportTripBlock,
    DisplayAvatar: () => import('@/components/ui/DisplayAvatar'),
    AcceptModal: () => import('@/composites/vehicle-rental/modals/AcceptModal'),
    RejectModal: () => import('@/composites/vehicle-rental/modals/RejectModal'),
    CancelModal: () => import('@/composites/vehicle-rental/modals/CancelModal'),
    DeliverModal: () =>
      import('@/composites/vehicle-rental/modals/DeliverModal'),
    StatusPillButton: () =>
      import('@/composites/vehicle-rental/shared/StatusPillButton'),
    RentalTimeline,
  },
  props: {
    rentalDetail: {
      required: false,
      default: null,
    },
    tripDetail: {
      required: false,
      default: null,
    },
  },
  computed: {
    ...mapGetters('auth', ['organizationInfo']),
    getInvoiceId() {
      return this.$truncate(this.rentalDetail?.invoice?.id, -5, '#') || '--'
    },
    getInvoiceDate() {
      return this.rentalDetail?.invoice?.date
        ? this.getUTCAwareTime(this.rentalDetail?.invoice?.date)
        : '--'
    },
    getStatusVariant() {
      return this.colors[this.rentalDetail.status] || 'blue'
    },
    getStatusText() {
      return this.texts[this.rentalDetail.status] || '--'
    },
  },
  data() {
    return {
      currentRentId: null,
      showAcceptModal: false,
      showRejectModal: false,
      showCancelModal: false,
      showDeliverModal: false,
      onTripDurationStr: null,
      colors: {
        REQUESTED: 'yellow',
        ACCEPTED: 'blue',
        REJECTED: 'red',
        CANCELLED: 'red',
        TRIP_COMPLETED: 'green',
        DELIVERED: 'purple',
      },
      texts: {
        REQUESTED: 'Pending',
        ACCEPTED: 'Accepted',
        REJECTED: 'Rejected',
        CANCELLED: 'Cancelled',
        TRIP_COMPLETED: 'Completed',
        DELIVERED: 'Rent Started',
      },
      symbol: '$',
      intervalid: null,
      pauseIntervalid: null,
      sockListener: null,
      onTripStatusText: 'Rent Started',
      onTripStatusVariant: 'purple',
      renderOnTripStatus: true,
    }
  },
  // watch: {
  //   onTripDurationStr(updated) {
  //     console.log('tmrender = ', updated.data.duration)
  //   },
  // },
  mounted() {
    this.symbol = this.organizationInfo.default_currency
      ? this.organizationInfo.default_currency.symbol
      : '$'
    console.log('symbol = ', this.symbol)
    if (this.rentalDetail.status === 'O') {
      console.log('Starting Bike Noti Listener')
      this.startListener()

      console.log('Refresh timer')
      // this.refreshTimer()
      this.intervalid = setInterval(this.refreshTimer, 1000)
      if (this.rentalDetail.is_paused) {
        let pauseData = this.rentalDetail.pause_entries.pop()
        this.pauseIntervalid = setInterval(() => {
          this.refreshPauseTimer(pauseData)
        }, 1000)

        this.onTripStatusText = 'On Pause'
        this.onTripStatusVariant = 'yellow'
      } else {
        this.onTripStatusText = 'Rent Started'
        this.onTripStatusVariant = 'purple'
      }
    }
  },
  beforeDestroy() {
    this.removeListener()
    this.removeOnTripTimer()
  },
  methods: {
    getUTCAwareTime,
    removeOnTripTimer() {
      if (this.intervalid) clearInterval(this.intervalid)
      if (this.pauseIntervalid) clearInterval(this.pauseIntervalid)
    },
    startListener() {
      let token = localStorage.getItem('token') || null
      // console.log(`Fetched Token : ${token}`)
      if (!token) return
      let sockUrl =
        SocketConfig.baseUrl +
        SocketConfig.channels.bikeNotifications +
        `?token=${token}`

      this.sockListener = this.sockListener
        ? this.sockListener
        : new ReconnectingWebsocket(sockUrl, null, SocketConfig.configs())

      this.sockListener.onopen = () => {
        console.log('Opened Noti Connection...')
      }
      this.sockListener.onclose = () => {
        console.log('Closed Noti Connection...')
      }
      this.sockListener.onmessage = this.onMessageHandler
    },
    removeListener() {
      if (this.sockListener) {
        this.sockListener.close(
          4003,
          'oto system closed the socket as the was closed'
        )
        this.sockListener = null
      }
    },
    onTripStatusforceRerender() {
      // Remove my-component from the DOM
      this.renderOnTripStatus = false

      this.$nextTick(() => {
        // Add the component back in
        this.renderOnTripStatus = true
      })
    },
    onMessageHandler(message) {
      console.log('Incoming --> ', message)
      let payload = JSON.parse(message.data)
      if (payload.n_type !== 'noti.bike_noti') return

      let bikeId = payload.bike_id
      if (!bikeId) return
      if (this.rentalDetail.bike.id !== bikeId) return

      console.log(`Incoming data for bike ${bikeId} : data -->`, payload.data)
      if ('trip_paused' in payload.data) {
        // console.log('Received pause socket msg', payload.data.trip_paused)
        // console.log('Received pause socket data', {
        //   ...payload.data.pause_data,
        // })
        let tripPaused = payload.data.trip_paused
        let pauseData = { ...payload.data.pause_data }
        this.rentalDetail.is_paused = tripPaused

        if (tripPaused) {
          this.pauseIntervalid = setInterval(() => {
            this.refreshPauseTimer(pauseData)
          }, 1000)
          this.onTripStatusText = 'On Pause'
          this.onTripStatusVariant = 'yellow'
        } else {
          clearInterval(this.pauseIntervalid)
          this.$refs.pauseDuration.innerHTML = '--'
          if (!this.intervalid)
            this.intervalid = setInterval(this.refreshTimer, 1000)
          this.onTripStatusText = 'Rent Started'
          this.onTripStatusVariant = 'purple'
        }
        this.onTripStatusforceRerender()
      }
    },
    goBack() {
      this.$router.push('/vehicle-rentals')
    },
    getCalculatedTimerStartTime() {
      let tripStatus = this.rentalDetail.status
      if (tripStatus == 'O') {
        return new Date(this.rentalDetail.pick_up_time)
      } else if (tripStatus == 'R') {
        return new Date(this.rentalDetail.reservation_start_time)
      } else {
        return null
      }
    },
    getTimerStr(timerStartTime) {
      let now = new Date()
      // console.log('now = ', now)
      // let offsetSecs = now.getTimezoneOffset() * 60
      var diffTimestamp = now - timerStartTime
      // console.log('tmr = ', diffTimestamp)

      var delta = Math.abs(diffTimestamp) / 1000
      // delta += offsetSecs
      var days = Math.floor(delta / 86400)
      delta -= days * 86400
      var hours = Math.floor(delta / 3600) % 24
      delta -= hours * 3600
      var minutes = Math.floor(delta / 60) % 60
      delta -= minutes * 60
      var seconds = Math.floor(delta % 60)

      // console.log(diffTimestamp, delta, days, hours, minutes, seconds)

      let tmStr = ''
      if (days > 0) {
        tmStr += `${days} d `
      }
      if (hours > 0) {
        tmStr += `${hours} h `
      }
      if (minutes > 0) {
        tmStr += `${minutes} m `
      }
      if (seconds > 0) {
        tmStr += `${seconds} s`
      }
      return tmStr
    },
    refreshTimer() {
      var timerStartTime = this.getCalculatedTimerStartTime()
      if (!timerStartTime) {
        return
      }
      let tmStr = this.getTimerStr(timerStartTime)
      // document.getElementById('onTripDurationStrSpan').innerHTML = tmStr
      // this.onTripDurationStr = tmStr
      this.$refs.tripDuration.innerHTML = `${tmStr}`
      // this.$forceUpdate()
      // console.log('tmr = ', tmStr, this.$refs.myDiv)
      // console.log('tmr = ', tmStr)
    },
    refreshPauseTimer(entry) {
      if (!entry.start_time) {
        return
      }
      let tmStr = this.getTimerStr(new Date(entry.start_time))
      // document.getElementById('onTripDurationStrSpan').innerHTML = tmStr
      // this.onTripDurationStr = tmStr
      this.$refs.pauseDuration.innerHTML = `${tmStr}`
      // this.$forceUpdate()
      // console.log('tmr = ', tmStr, this.$refs.myDiv)
      // console.log('tmr = ', tmStr)
    },

    async onPerformAction({ id, action } = {}) {
      if (this.$acl.canNotUpdate('trips')) {
        this.$notify(this.$acl.notAbleToToast())
        return
      }

      console.log('onPerformAction', id, action)

      if (action === 'accept') {
        this.currentRentId = id
        this.showAcceptModal = true
        return
      }

      if (action === 'reject') {
        this.currentRentId = id
        this.showRejectModal = true
        return
      }

      if (action === 'deliver') {
        this.currentRentId = id
        this.showDeliverModal = true
        return
      }

      if (action === 'cancel') {
        this.currentRentId = id
        this.showCancelModal = true
        return
      }
    },
    onAcceptModalClose(shouldUpdate) {
      this.currentRentId = null
      this.showAcceptModal = false

      if (shouldUpdate) {
        this.$emit('updated')
        this.$refs.rentalTimeline.refreshLogs()
      }
    },
    onRejectModalClose(shouldUpdate) {
      this.currentRentId = null
      this.showRejectModal = false

      if (shouldUpdate) {
        this.$emit('updated')
        this.$refs.rentalTimeline.refreshLogs()
      }
    },
    onCancelModalClose(shouldUpdate) {
      this.currentRentId = null
      this.showCancelModal = false

      if (shouldUpdate) {
        this.$emit('updated')
        this.$refs.rentalTimeline.refreshLogs()
      }
    },
    onDeliverModalClose(shouldUpdate) {
      this.currentRentId = null
      this.showDeliverModal = false

      if (shouldUpdate) {
        this.$emit('updated')
        this.$refs.rentalTimeline.refreshLogs()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.group-trip-badge {
  background-color: #2563eb !important;
  color: #fff;
  font-weight: 400 !important;
  font-size: 12px !important;
  text-transform: uppercase;
  padding: 4px 10px;
  border-radius: 14px;
  margin-left: 15px !important;
}

.rental-status-pill-button {
  width: 170px;
  @apply flex items-center justify-between shadow-md rounded-full h-35px;
  @apply px-3 py-0 pl-3 text-center cursor-pointer;

  &.fallback {
    @apply bg-oDark text-white;
  }
  &.blue {
    background-color: #0053ff;
    @apply text-white;
  }
  &.blush {
    @apply bg-oBlush text-white;
  }
  &.purple {
    @apply bg-oPurple text-white;
  }
  &.red {
    @apply bg-oRed text-white;
  }
  &.green {
    @apply bg-oGreen text-white;
  }
  &.yellow {
    @apply bg-oYellow text-oDark;
  }
  &.orange {
    @apply bg-oOrange text-white;
  }
}
</style>
